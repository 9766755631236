/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as ArrowDownIcon } from '../../images/icons/arrowDown.svg';
import { ReactComponent as ArrowRight } from '../../images/icons/arrowRight.svg';

import styles from './BlogGrid.module.scss';

import CreateGrid from './CreateGrid';
import { useLoaderData } from 'react-router-dom';

const BlogGrid = () => {
  const [amountOfPosts, setAmountOfPosts] = useState(12);
  const { blogData } = useLoaderData();

  if (!blogData?.length) return null;

  const posts = blogData.slice(0, amountOfPosts);

  return (
    <section className={styles.blogGrid}>
      <div className="container">
        <div className={classNames(styles.blogGrid, 'row')}>
          <div className={classNames('col-12-xs', styles.blogGridMobileRow)}>
            <CreateGrid blogPosts={posts} slideWidth={535} sliderHeight={555} articleWidth={260} articleHeight={175} />
            {amountOfPosts + 4 < blogData.length && (
              <button
                className={classNames(styles.showMoreBtn, styles.showMoreBtnMobile)}
                onClick={() => setAmountOfPosts(amountOfPosts + 12)}
              >
                <div className={classNames(styles.iconWrapper)}>
                  <ArrowRight />
                </div>
                <FormattedMessage id="common.showMore" defaultMessage="show More" />
              </button>
            )}
          </div>
          {amountOfPosts + 4 < blogData.length && (
            <button className={styles.showMoreBtn} onClick={() => setAmountOfPosts(amountOfPosts + 12)}>
              <FormattedMessage id="common.showMore" defaultMessage="show More" />
              <ArrowDownIcon />
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default BlogGrid;
